html, body {
  height: 100vh;
  margin: 0;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'Impact';
  src: url(./fonts/impact.ttf) format('trueType');
}

@font-face {
  font-family: 'WorkSans';
  src: url(./fonts/WorkSans-Medium.ttf) format('trueType');
}

@font-face {
  font-family: 'WorkSansBold';
  src: url(./fonts/WorkSans-Bold.ttf) format('trueType');
  font-weight: bold;
}

b, strong {
  font-family: 'WorkSansBold', sans-serif; 
}

p{
  margin: 0;
}

nav ul {
  list-style: none; 
  margin: 0; 
  padding: 0; 
}
