.homeContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    padding-top: 65px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow: hidden;
}

.childContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.logoContainer {
    width: 100%;
    max-width: 650px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.imgLogo {
    height: 60%;
    width: 60%;
}

.walletContainer {
    min-height: 20%;
    width: 79%;
    max-width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

.titleWallet {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: Impact;
    color: #051129;
    font-size: 2.25em;
    text-align: center;
    line-height: 100%;
    margin-top: 10px;
}

.titleWallet1 {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: Impact;
    color: #1725eb;
    font-size: 1.5em;
    text-align: center;
    line-height: 100%;
    margin-top: 10px;

    /* Hiệu ứng bốc cháy */
    animation-name: burn;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; 
    opacity: 0; 
}



.burn-animation {
    animation-name: burn;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; /* Giữ lại trạng thái cuối cùng của animation */
}

@keyframes burn {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
.textWallet {
    font-family: WorkSans;
    font-size: 1.2em;
    text-align: center;
    line-height: 150%;
    color: #00181a;
    margin-bottom: 0px;
}

.textWallet1 {
    font-family: WorkSans;
    font-size: 1.2em;
    text-align: center;
    line-height: 150%;
    font-style: italic;
    color: #bb0092;
    margin-top: 15px;
}


.modelePourcent {
    width: 100%;
    height: 50px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: bottom;
    font-family: Impact;
    font-size: 1.5em;
    position: relative;
    overflow: hidden;
    background-color: #a8a8a8;
    color: #082F32;
    margin-bottom: 10px;
}

.imgBottom {
    width: 100%;
    margin-top: 70px;
    z-index: 1;
}

.ligne {
    height: 2px;
    width: 39%;
    background-color: #A8A8A8;
    /* margin-bottom: 10px; */
    margin-top: 10px;
}

.containerDevise {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    margin-top: 10px;
}

.containerDevise1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;
    margin-top: 10px;
}


.containerMount {
    margin-left: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    margin-bottom: 15px;
    /* margin-top: 5px; */
}

.inputMount {
    width: 100%;
    height: 100%;
    /* background-color: #237A89; */
    /* border-radius: 10px; */
    /* border: none; */
    /* outline: none; */
    color: rgb(255, 72, 0);
    font-family: WorkSans;
    font-size: 15px;
    /* box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0.2, 0.3); */
    /* box-sizing: border-box; */
    /* padding-left: 13px; */
}

.containerInput {
    width: 50%;
    height: 100%;
    font-family: WorkSans;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.textTopInput {
    font-size: 1.2em;
    height: 55%;
    color: #237A89;
}
.textTopInput1 {
    font-size: 1.2em;
    height: 55%;
    color: #237A89;
}



.leftLogo {

    position: absolute;
    left: 0;
    top: 0;
    width: 39%;
    min-width: 200px;
    max-width: 800px;
    z-index: 0;
}

.rightLogo {

    position: absolute;
    right: 0;
    top: 0;
    width: 39%;
    z-index: 0;
    min-width: 200px;
    max-width: 800px;
}

.colorModelePourcent {
    position: absolute;
    width: 19%;
    text-align: center;
    background-color: #19d5f7;
    height: 100%;
    left: 0;
}

.logoCrypto {
    /* margin-right: 10px; */
    max-width: 50px;
    max-height: 50px;
}

.logoCrypto1 {
    max-width: 50%;
    align-items: 'center' !important;
    max-height: 30%;
}

.containerStat {
    height: 120px;
    width: 100%;
    background-color: #237A89;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

}

.childStat {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: #eb8f16;
}

.textStat {
    font-family: WorkSans;
    font-size: 1.2em;
}

.numberStat {
    font-family: Impact;
    font-size: 1.5em;
}

#api {
    transform: scale(1.1);
    background-color: #30c3fd;
    min-width: 250px;
    z-index: 1;
}

#apiText {
    font-size: 1.3em;
}

#apiNumber {
    font-size: 2.6em;
}

.leftStat {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
}

.rightStat {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
}

@media only screen and (max-width: 1200px) {
    .titleWallet {
        white-space: pre-wrap;
        /* Allow wrapping on spaces */
        font-family: Impact;
        color: #082F32;
        font-size: 1.8em;
        text-align: center;
        line-height: 150%;
        margin-top: 15px;
    }

    .modelePourcent {
        width: 100%;
        height: 50px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Impact;
        font-size: 0.9em;
        position: relative;
        overflow: hidden;
        background-color: #bda4a4;
        color: #082F32;
        margin-bottom: 20px;
        text-align: center;
    }

    .textTopInput {
        font-size: 1em;
        height: 25%;
        color: #185C64;
    }

    .leftLogo {
        position: fixed;
        left: 0;
        top: 65px;
        width: 33%;
        min-width: 170px;
        z-index: -2;
    }

    .rightLogo {
        position: fixed;
        right: 0;
        top: 65px;
        width: 33%;
        z-index: 0;
        min-width: 170px;
        z-index: -2;
    }

    .containerStat {
        height: auto;
        width: 100%;
        background-color: #185C64;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .textStat {
        font-family: WorkSans;
        font-size: 2.7vw;
    }

    .numberStat {
        font-family: Impact;
        font-size: 4.2vw;
    }

    .childStat {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 90%;
        color: white;
        min-height: 80px;
    }

    .leftStat {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .rightStat {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    #api {
        background-color: #7ae0fa;
        min-width: 200px;
        width: 100%;
        min-height: 100px;
    }

    #apiText {
        font-size: 1em;
    }

    #apiNumber {
        font-size: 2.5em;
    }
}

/* Animation */
@keyframes buttonAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    120% {
        transform: scale(1.1);
    }
}

.buttonDevise {
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
    /* align-items: right; */
    color: rgb(255, 255, 255);
    font-family: 'Franklin Gothic Medium';
    cursor: auto;
    /* transition: box-shadow 0.3s ease; */
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
}
/* Hover Effect */
.buttonDevise:hover {
    background-color: #6dd6e4;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    animation: buttonAnimation 0.7s ease;
}

.buttonDevise1 {
    width: 50%;
    height: 50%;
    background-color: #237A89;
    border-radius: 15px;
    /* padding-right: 15; */
    /* margin-left: 25px; */
    /* margin-right: 25px; */
    display: flex;
    justify-content: center;
    /* align-items: right; */
    color: rgb(255, 255, 255);
    font-family: 'Franklin Gothic Medium';
    cursor: auto;
    /* transition: box-shadow 0.3s ease; */
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
}
/* Hover Effect */
.buttonDevise1:hover {
    background-color: #6dd6e4;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    animation: buttonAnimation 0.7s ease;
}

.modeleButton {
    width: 60%;
    height: 50px;
    /* margin-top: 15px; */
    align-items: 'center' !important;
    background-color: #237A89;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: WorkSans;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.modeleButton1 {
    width: 100%;
    height: 100px;
    margin-top: 15px;
    background-color: #237A89;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: WorkSans;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Hover Effect */
.modeleButton:hover {
    background-color: #5ec1d3;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.modeleButton:hover {
    animation: buttonAnimation 0.5s ease;
}

.modeleButtonCgv {
    position: relative;
    width: 90%;
    height: 100%;
    background-color: #145861e7;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: WorkSans;
    cursor: auto;
    transition: box-shadow 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    margin-bottom: 10px;
}
/* Hover Effect */
.modeleButtonCgv:hover {
    background-color: #0f4c5c;
    box-shadow: 0px 6px 12px rgba(0, 0, 0.2, 0.3);
    animation: buttonAnimation 0.7s;
    transform: scale(1.05); 
}

.custom-card-container {
    width: 150px;
    height: 180px;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 15px;
    background-color: #000814;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
  }
  
  .custom-logo {
    max-width: 50px !important;
  }
  
  .custom-card {
    position: absolute;
    width: 95%;
    height: 95%;
    background: #145861;
    border-radius: 15px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  }
  
  .custom-card h2,
  .custom-card span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: 800;
    pointer-events: none;
    opacity: 0.5;
  }
  
  .custom-card span {
    top: 75%;
  }
  
  .custom-card .custom-content h3 {
    font-size: 1rem;
    padding-bottom: 10px;
  }
  
  .custom-card .custom-content p {
    font-size: 0.7rem;
    line-height: 20px;
  }
  
  .custom-card .custom-content {
    transform: translateY(100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  
  .custom-card:hover .custom-content {
    transform: translateY(0);
    opacity: 1;
  }
  
  .custom-card:hover h2,
  .custom-card:hover span {
    opacity: 0;
  }
  
  .custom-card-container::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 150%;
    background: #18100b;
    background: -webkit-linear-gradient(to right, #36363d, #969ef8, #c5d6e6, #ffffff, #95aabe, #2f3438);
    background: linear-gradient(to right, #3f4449, #9da3df, #c5d6e6, #ffffff, #c5d6e6, #393b41);
    animation: glowing01 6s linear infinite;
    transform-origin: center;
  }
  
  @keyframes glowing01 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

@media (max-width: 768px) {
    .modeleButtonCgv {
        width: 100%;
        margin: 10 auto;
    }

    .buttonDevise {
        width: 80%;
        margin: 5 auto;
        text-justify: inherit;
    }

    .modeleButton {
        width: 80%;
        margin: 10 auto;
        text-justify: inherit;
    }
    .containerDevise {
        width: 70%;
        margin: 10 auto;
        margin-right: 10px;
        text-justify: inherit;
        justify-content: space-between;
        align-items: center;
        height: 49px;
        margin-top: 20px;
    }

    .custom-card-container {
        display: flexbox;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px auto;
        margin-right: 10px;
        margin-top: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    
}